@use "partials";

.release_page {
  --grid-column-gap: #{partials.line-height("m")};
  --grid-row-gap: #{partials.line-height("m")};

  display: grid;
  column-gap: var(--grid-column-gap);
  row-gap: var(--grid-row-gap);
  grid-auto-rows: min-content;

  @include partials.media(">=tablet") {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto 1fr;
    align-items: start;
    align-content: start;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: start;
    align-self: start;

    @include partials.media(">=tablet") {
      grid-column: 2;
      grid-row: 1;
    }

    h1 {
      text-align: left;
    }
  }

  &__slideshow {
    height: 0;
    padding-bottom: calc(100% * var(--sleeve-aspect-ratio));
    position: relative;

    @include partials.media(">=tablet") {
      grid-column: 1;
      grid-row: 1 / -1;
      align-self: stretch;
      position: relative;
    }

    > .slideshow {
      position: absolute;
      top: 0;
      left: 0;
    }

    * {
      width: 100%;
      height: 100%;
    }

    picture {
      display: block;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__description {
    @include partials.media(">=tablet") {
      grid-column: 1;
      grid-row: 2 / 3;
      align-self: start;
    }

    > * + * {
      margin-top: partials.line-height("m");
    }
  }
}
