html {
  font-size: 62.5%;
}

* {
  box-sizing: border-box;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

ol,
ul {
  list-style: none;
  padding: 0;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  background-color: transparent;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  font: inherit;
  border: 0;
  padding: 0;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  &[disabled] {
    cursor: default;
  }
}

img {
  max-width: 100%;
}

table {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-collapse: collapse;
  border-spacing: 0;
  background-image: none;
}
