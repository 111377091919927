@use "partials";

.stock__header {
  @include partials.type-size("m");
}

.stock__sleeve {
  aspect-ratio: 1 / 1;
  position: relative;
  overflow: hidden;
  margin-bottom: partials.line-height("m") * 0.5;

  * {
    width: 100%;
    height: 100%;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgb(0, 0, 0);
    z-index: -1;
  }

  a {
    display: block;
  }

  picture {
    display: flex;
    align-content: flex-start;
    align-items: flex-end;

    source {
      display: none;
    }
  }

  [data-format="LP"] & {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  [data-format='7"'] & {
    img {
      width: 70%;
      height: auto;
    }
  }
}

.stock__sold_out {
  @include partials.line-through;
  white-space: nowrap;
}

.stock__purchase {
  color: var(--color-highlight);
  display: flex;
  align-items: flex-start;

  > * + * {
    margin-left: 0.5em;
  }
}

.stock__add_to_cart {
  @include partials.button;
}
