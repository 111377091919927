@use "partials";

.stock_page {
  --grid-column-gap: #{partials.line-height("m") * 3};
  --grid-row-gap: #{partials.line-height("m")};

  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns: 1fr;
  column-gap: var(--grid-column-gap);
  row-gap: var(--grid-row-gap);

  @include partials.media(">=tablet") {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto 1fr;
    grid-auto-rows: min-content;
    align-items: start;
    align-content: start;
    grid-auto-rows: min-content;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: start;
    align-self: start;

    @include partials.media(">=tablet") {
      grid-column: 2;
      grid-row: 1;
    }
  }

  &__sleeve {
    @include partials.media(">=tablet") {
      grid-column: 1;
      grid-row: 1 / -1;
      align-self: stretch;
      position: relative;
    }

    &::after {
      @include partials.media(">=tablet") {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: calc(var(--grid-column-gap) * -0.5 - 1px);
        width: 1px;
        background-color: currentColor;
      }
    }
  }

  &__title {
    text-align: left;
  }

  &__slideshow {
    height: 0;
    padding-bottom: calc(100% * var(--sleeve-aspect-ratio));
    position: relative;

    .slideshow {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .slideshow__slides {
      width: 100%;
      height: 100%;

      * {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__content {
    @include partials.media(">=tablet") {
      grid-column: 2;
      grid-row: 2 / 3;
      align-self: start;
    }

    > * + * {
      margin-top: partials.line-height("m");
    }
  }

  &__tracks {
    border-top: 1px solid currentColor;
    padding-top: partials.line-height("m");

    h2 {
      margin-bottom: partials.line-height("m") * 0.5;
    }

    a {
      @include partials.underline;
    }
  }
}
