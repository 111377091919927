@use "partials";

:root {
  --grid-column-gap: #{partials.line-height("m")};
}

html {
  height: 100%;
}

body {
  display: grid;
  grid-template-rows: min-content auto min-content;
  grid-template-columns: repeat(1, 1fr);
  column-gap: var(--grid-column-gap);
  row-gap: 0;
  grid-template-rows: auto 1fr auto;
  min-height: 100%;

  padding: partials.line-height("m") partials.line-height("m")
    partials.line-height("m") * 1.5 partials.line-height("m");
  margin: 0;

  background: var(--color-main-background);
  color: var(--color-main);

  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale;

  @include partials.media(">=tablet") {
    grid-template-columns: repeat(8, 1fr);
  }

  @include partials.media(">=desktop") {
    grid-template-columns: repeat(10, 1fr);
  }

  nav {
    margin-bottom: partials.line-height("m") * 2;
  }

  > nav,
  > footer {
    grid-column-start: 1;
    grid-column-end: -1;
  }

  > main {
    grid-column-start: 1;
    grid-column-end: -1;
    margin-bottom: partials.line-height("m") * 3;

    @include partials.media(">=tablet") {
      grid-column-start: 2;
      grid-column-end: 8;
    }

    @include partials.media(">=desktop") {
      grid-column-start: 4;
      grid-column-end: 8;
    }
  }
}

h1,
nav,
footer {
  text-align: center;
}

a {
  color: inherit;
}
