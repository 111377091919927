@use "partials";

@keyframes attempt-forwards {
  50% {
    transform: rotateY(-15deg);
  }
}

@keyframes attempt-backwards {
  50% {
    transform: rotateY(15deg);
  }
}

.slideshow {
  --transition-duration: 0s;

  width: 100%;
  position: relative;
  z-index: 1;
  perspective: 1200px;

  &__slides {
    width: 100%;
    position: relative;
    transform-style: preserve-3d;
    transition: transform var(--transition-duration);
    will-change: transform;
  }

  &__slide {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    backface-visibility: hidden;
    visibility: hidden;

    &--current {
      visibility: visible;
      transform: rotateY(0deg);
    }

    &--next {
      transform: rotateY(180deg);
    }

    &--previous {
      transform: rotateY(-180deg);
    }
  }

  &__button {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    z-index: 2;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 100%;
    }

    @media (hover: hover) {
      &:hover {
        &::before {
          border: 1px solid black;
        }
      }
    }

    &--previous {
      left: 0;
    }

    &--next {
      right: 0;
    }
  }
}

.slideshow--attempt_forwards {
  .slideshow__slides {
    animation: 0.3s forwards ease-in-out attempt-forwards;
    animation-iteration-count: 1;
  }
}

.slideshow--attempt_backwards {
  .slideshow__slides {
    animation: 0.3s forwards ease-in-out attempt-backwards;
    animation-iteration-count: 1;
  }
}

.slideshow.slideshow--forwards {
  --transition-duration: 0.6s;

  .slideshow__slides {
    transform: rotateY(-180deg);
  }

  .slideshow__slide--next {
    visibility: visible;
  }
}

.slideshow.slideshow--backwards {
  --transition-duration: 0.6s;

  .slideshow__slides {
    transform: rotateY(180deg);
  }

  .slideshow__slide--previous {
    visibility: visible;
  }

  // .slideshow__slide--previous {
  //   visibility: visible;
  //   transform: rotateY(0deg);
  // }
}
