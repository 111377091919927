@use "partials";

.release {
  &__sleeve {
    margin-bottom: partials.line-height("m") * 0.5;
    aspect-ratio: 1240 / 1220;

    * {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
  }
}
