.color-highlight {
  color: var(--color-highlight);
}

.color-main {
  color: var(--color-main);
}

.color-background {
  color: var(--color-main-background);
}

.background-color-highlight {
  background-color: var(--color-highlight);
}

.background-color-main {
  background-color: var(--color-main);
}

.background-color-background {
  background-color: var(--color-main-background);
}
