@use "partials";

.info__section {
  &__image {
    margin-top: 0.5em;

    &[data-file="avatar.svg"] {
      height: 3.8em;
    }

    &[data-file="pearl.svg"] {
      height: 4em;
    }

    &[data-file="oyster.svg"] {
      height: 5.5em;
    }

    &[data-file="mixed_signals.svg"] {
      height: 5em;
    }

    svg {
      overflow: visible;
      width: auto;
      max-width: 100%;
      height: 100%;
      display: block;

      line,
      path {
        stroke: currentColor;
        fill: none;
      }
    }
  }

  table {
    width: 100%;
    border-bottom: 1px solid currentColor;

    th {
      display: none;
    }

    td {
      border-top: 1px solid currentColor;
      padding-top: 0.25em;
      padding-bottom: 0.25em;

      &:first-child {
        padding-left: 0;
        border-bottom: 0;
      }
    }
  }
}
