:root {
  --black: #000;
  --white: #fff;
  --yellow: yellow;
  --blue: blue;
  --grey: grey;
  --grey-dark: #555;
  --color-main: var(--white);
  --color-main-background: var(--black);
  --color-highlight: var(--yellow);
}
