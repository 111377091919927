@use "partials";

body.home {
  > main {
    grid-column-start: 1;
    grid-column-end: -1;
    margin-bottom: partials.line-height("m") * 3;

    @include partials.media(">=desktop") {
      grid-column-start: 2;
      grid-column-end: 10;
    }
  }

  .releases {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: var(--grid-column-gap);
    grid-row-gap: partials.line-height("m") * 2;

    @include partials.media(">=tablet") {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
