@use "partials";

body.stock {
  --color-main-background: var(--white);
  --color-main: var(--black);
  --color-highlight: var(--blue);

  > main {
    grid-column-start: 1;
    grid-column-end: -1;
    align-items: start;
    align-content: start;
    grid-auto-rows: auto;

    @include partials.media(">=tablet") {
      grid-template-columns: repeat(6, 1fr);

      grid-column-start: 1;
      grid-column-end: 9;
    }

    @include partials.media(">=desktop") {
      grid-template-columns: repeat(8, 1fr);

      grid-column-start: 2;
      grid-column-end: 10;
    }
  }
}
