@use "partials";

body.vintage_stock {
  --color-main-background: var(--white);
  --color-main: var(--black);
  --color-highlight: var(--blue);
  --grid-column-gap: #{partials.line-height("m") * 3};
  --grid-row-gap: #{partials.line-height("m") * 2};

  > main {
    grid-column-start: 1;
    grid-column-end: -1;

    padding-left: calc(var(--grid-column-gap) / 2);
    padding-right: calc(var(--grid-column-gap) / 2);
  }

  .stock__records {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: var(--grid-column-gap);
    grid-row-gap: var(--grid-row-gap);
    padding-bottom: partials.line-height("m") * 3;
    overflow: hidden;

    @include partials.media(">=tablet") {
      grid-template-columns: repeat(2, 1fr);
    }

    @include partials.media(">=desktop") {
      grid-template-columns: repeat(3, 1fr);
    }

    > * {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 4px;
        left: calc(var(--grid-column-gap) * -0.5);
        width: 1px;
        background-color: currentColor;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 4px;
        right: calc(var(--grid-column-gap) * -0.5 - 1px);
        width: 1px;
        background-color: currentColor;
      }
    }
  }
}
