@use "partials";

.release__purchase {
  color: var(--color-highlight);
  display: flex;
  align-items: flex-start;

  > * + * {
    margin-left: 0.5em;
  }
}
