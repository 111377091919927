@use "partials";

@font-face {
  font-family: "Smiling Times";
  src: url("../fonts/smiling-times-regular.woff2") format("woff2"),
    url("../fonts/smiling-times-regular.woff") format("woff");
}

body {
  @include partials.type-size("m");
  font-family: "Smiling Times", "Times New Roman", Times, serif;
  letter-spacing: 0.02em;

  text-rendering: geometricPrecision !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale;
}
