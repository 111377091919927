@mixin line-through {
  position: relative;
  padding: 0 0.2em;

  &::before {
    content: "";
    border: 0;
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    left: 0;
    top: 50%;
    transform: rotate(-6deg);
  }
}

@mixin button {
  position: relative;
  white-space: nowrap;

  &:not([disabled]) {
    padding: 0 0.75em;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 15%;
      bottom: -25%;
      border: 1px solid currentColor;
      border-top: 0;
      border-radius: 0 0 50% 50% / 0 0 100% 100%;
      z-index: -1;
    }
  }

  &[disabled] {
    @include line-through;
  }
}
