@use "partials";

.snipcart_button {
  @include partials.button;

  &[data-snipcart-button-loading-value="true"] {
    padding: 0;

    &::before {
      display: none;
    }
  }
}
