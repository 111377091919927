@use "partials";

.text {
  p {
    margin-bottom: partials.line-height("m");

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    @include partials.underline;

    &:hover {
      color: var(--color-highlight);
    }
  }
}
