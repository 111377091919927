@use "partials";

.main_menu {
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  &__menu {
    grid-column: 1 / 2;
    grid-row: 1 / 2;

    @include partials.media(">=tablet") {
      display: flex;
      justify-content: center;
    }
  }

  &__items {
    display: flex;
    justify-content: center;
  }

  &__smiling_c {
    text-align: left;

    @include partials.media("<tablet") {
      margin-bottom: 0.5em;
    }

    @include partials.media(">=tablet") {
      margin-right: 0.6em;
    }
  }

  a {
    border-radius: 100%;
    padding: 0.15em 0.3em;
    border: 1px solid transparent;

    &[aria-current] {
      border-color: currentColor;
    }
  }

  &__cart {
    @include partials.button;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    align-self: start;
    justify-self: end;

    .snipcart-items-count {
      display: inline-flex;

      &:empty {
        &::before {
          content: "0";
          opacity: 0;
        }
      }
    }
  }
}
