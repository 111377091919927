@use "partials";

@keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}

body.instagram {
  grid-template-rows: 1fr auto;

  h1 {
    margin-bottom: partials.line-height("m") * 2;

    a {
      &:hover {
        @include partials.underline;
      }
    }
  }

  > main {
    grid-column-start: 1;
    grid-column-end: -1;
    margin-bottom: partials.line-height("m") * 3;

    text-align: center;

    @include partials.media(">=desktop") {
      grid-column-start: 2;
      grid-column-end: 10;
    }

    ul {
      margin-bottom: partials.line-height("m");
    }

    li {
      margin-bottom: 1px;

      a {
        padding: 0.5em 1em;
        border-radius: 0.5em;
        color: var(--color-main-background);
        background-color: var(--color-main);
        display: inline-block;

        &:hover {
          @include partials.iridescent-background;
          animation: rainbow 6s ease infinite;
          background-size: 1800% 1800%;
        }
      }
    }
  }
}
