.tab_container {
  display: block;

  &__tabs {
    display: flex;
    padding-bottom: 0.5em;
  }

  &__tab {
    padding: 0.25em 0.75em 0 0.75em;
    cursor: pointer;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: -15%;
      bottom: 10%;
      border: 1px solid currentColor;
      border-bottom: 0;
      border-radius: 50% 50% 0 0 / 100% 100% 0 0;
    }

    &[aria-selected="false"] {
      opacity: 0.5;
    }
  }

  &__panel {
    &:focus {
      outline: 0;
    }
  }
}
