@use "partials";

.main_footer {
  color: var(--grey);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: partials.line-height("m");
  // border-top: 1px solid currentColor;

  > * + * {
    margin-left: 0.75em;
  }

  a {
    &:hover {
      @include partials.underline;
    }
  }
}
