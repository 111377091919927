@use "partials";

body.release {
  > main {
    // display: grid;
    // grid-template-columns: 1;
    // column-gap: var(--grid-column-gap);
    // row-gap: partials.line-height("m");

    grid-column-start: 1;
    grid-column-end: -1;
    align-content: start;

    margin-bottom: partials.line-height("m") * 3;

    @include partials.media(">=tablet") {
      grid-template-columns: repeat(6, 1fr);

      grid-column-start: 1;
      grid-column-end: 9;
    }

    @include partials.media(">=desktop") {
      grid-template-columns: repeat(8, 1fr);

      grid-column-start: 2;
      grid-column-end: 10;
    }

    > header {
      grid-column-start: 1;
      grid-column-end: -1;
    }

    > section {
      @include partials.media(">=tablet") {
        grid-column: span 3;
      }

      @include partials.media(">=desktop") {
        grid-column: span 4;
      }

      > * + * {
        margin-top: partials.line-height("m") * 2;
      }
    }
  }
}
